.throbber {
  &-post-like,
  &-post-hide {
    margin: 0 2px 0 5px;
  }
  &-post-edit {
    margin-right: 10px;
  }
  &-more-likes {
    margin-left: 5px;
  }
  &-more-comments {
    position: absolute;
    margin-top: 1px;
  }
  &-comment-attachment {
    margin: 0 5px 0 10px;
  }
  &-comment-edit {
    margin-right: 10px;
  }
  &-clikes {
    margin: -3px 0 0 2px;
  }
  &-user-card {
    margin-left: 5px;
  }
  &-profile-controls {
    margin: 0 5px 0 8px;
  }
  &-settings {
    margin-left: 10px;
  }
}
