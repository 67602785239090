ul.tile-list {
  padding: 10px 0 0 0;
  margin: 0 -5px;

  .user-tile {
    list-style-type: none;

    text-align: center;
    min-width: 110px;
    height: 110px;
    padding: 0 5px;

    .userpic {
      margin-bottom: 1px + 5px;

      .userpic-overlay {
        position: absolute;
        margin: -50px 0 0 -0px;
        width: 50px;
        height: 50px;

        text-align: center;
        padding-top: 16px;
        background-color: rgba(255, 255, 255, 0.9);
      }
    }

    .user-tile-name {
      display: block;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &.with-actions {
    margin: 0 -15px;

    .user-tile {
      text-align: left;
      height: 85px;
      padding: 8px 15px 0 15px;

      .userpic {
        float: left;
        margin-right: 1px + 10px;
        margin-bottom: 1px;
      }

      .user-tile-name {
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .user-actions {
    margin-top: 13px;

    .user-action {
      margin-right: 10px;

      &.user-action-good {
        color: #3c763d;
      }

      &.user-action-bad {
        color: #a94442;
      }

      [class^="icon-"], [class*=" icon-"] {
        margin-right: 3px;
      }
    }
  }

  &.large-pics .user-tile {
    height: 140px;
  }
}

.list-controls {
  .manage-controls {
    text-align: right;
    float: right;
  }
  .filter-controls {
    float: left;
  }
}
