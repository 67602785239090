.character-counter {
  position: absolute;
  display: block;

  top: 25px;
  right: 11px;
  z-index: 2;

  color: #999;
  font-size: 13px;
  line-height: 34px;
  height: 34px;
  margin: 0;

  pointer-events: none;

  .has-error & {
    color: #d00;
  }
}

.radio-feedVisibility {
  margin-top: 5px;
}

.radio-groupVisibility {
  margin-top: 0;
}

.checkbox-displayNames-useYou {
  margin: 20px 0;
}

.preferences-form-group {
  margin-bottom: 25px;
}
