.post-recipients {
  .post-recipients-label {
    float: left;
    margin-top: 7px;
  }

  .react-select {
    &-container {
      margin: 0 0 3px 20px;
    }

    &__control {
      border: none;
      box-shadow: none !important; // to remove "outline" on focus
      min-height: inherit;
    }

    &__value-container {
      padding: 3px 5px;

      // Add custom visual trigger instead of "placeholder" prop,
      // (the latter is only displayed when nothing is selected)
      &:after {
        content: 'Add/Edit...';
        color: #ccc;
        font-size: 0.9em;
        margin-left: -2px;
      }
    }

    // This can only be styled with CSS, not a style object
    &__control--is-focused .react-select__value-container:after {
      content: '';
    }

    &__multi-value {
      background-color: #ebf5ff;
      border: 1px solid #b3d9ff;
      border-radius: 3px;
      margin: 2px 5px 2px 0;

      &__label {
        color: #555599;
        font-size: 0.9em;
        padding: 2px 4px 2px 5px;
      }

      &__remove {
        padding: 1px 3px 0 3px;

        &:hover {
          color: #fff;
        }
      }
    }

    &__menu {
      margin-top: 0;
      border-radius: 4px;
      border: 1px solid #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      z-index: 2000;
    }

    &__menu-list {
      max-height: 200px;
      padding: 0 10px 10px 0;
    }

    &__group {
      padding: 6px 0;
    }

    &__group-heading {
      color: #666;
      font-size: 1em;
      font-weight: bold;
      text-transform: none;
      padding: 0;
      margin: 10px 0 0 10px;
    }

    &__option {
      display: inline-block;
      width: auto;
      font-size: 0.9em;
      color: #555599;
      background-color: #ebf5ff;
      border: 1px solid #b3d9ff;
      border-radius: 3px;
      padding: 2px 5px;
      margin: 10px 0 0 10px;

      &--is-focused {
        color: #333;
        background-color: #b3d9ff;
      }
    }
  }
}

// textarea for add|edit posts and comments
@mixin editarea {
  padding: 3px 5px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 6px;
}

label.option-box {
  display: block;

  .input {
    display: table-cell;
  }

  .option {
    display: table-cell;
    font-weight: normal;
    padding-left: 10px;
    padding-bottom: 5px;
  }
}
