.tippy-box[data-theme~='gamma'] {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
  padding: 3px 6px;

  &[data-animation='fade'] {
    &[data-state='visible'] {
      opacity: 1;
    }
    &[data-state='hidden'] {
      opacity: 0;

      .tippy-arrow:before {
        display: none;
      }
    }
  }

  .tippy-arrow:before {
    // [x-arrow] is "CSS triangle" in Tippy.js, but we still need a rotated square for proper box-shadow
    content: '';
    position: absolute;
    z-index: -1;
    left: -5px;
    top: -5px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
  }

  &[data-placement^=bottom] > .tippy-arrow {
    top: 0;

    &:before {
      background-color: #fff;
      box-shadow: -2px -2px 3px -1px rgba(0, 0, 0, 0.5);
    }
  }

  &[data-placement^=top] > .tippy-arrow {
    bottom: 0;

    &:before {
      background-color: #f4f4f4;
      box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.5);
    }
  }

  .tippy-content {
    position: relative;
    border-radius: 2px;
    z-index: 1;

    background-color: #fff;
    color: #333;
    font-size: 12px;
    text-align: left;
    line-height: 1.5;

    max-width: 180px;
    max-height: 180px;

    overflow-x: hidden;
    overflow-y: auto;
  }
}

.tippy-box[data-theme~='gamma-dropdown'] {
  padding: 0;

  .tippy-content {
    font-size: 14px;
    line-height: 19px;
    max-width: 200px;
    max-height: 222px;
  }
}

.tippy-box[data-theme~='gamma-usercard'] {
  padding: 0;

  .tippy-content {
    max-width: none;
    max-height: none;
  }
}
