.userpic {
  display: inline-block;
  border: none;
  margin: 1px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);

  width: 50px;
  height: 50px;
  border-radius: 1px;

  img {
    border-radius: 1px;
  }

  &.userpic-large {
    width: 75px;
    height: 75px;
    border-radius: 2px;

    img {
      border-radius: 2px;
    }
  }

  &.userpic-loading {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
    background-color: #f2f2f2;
  }

  &.userpic-error {
    color: #ffd9d9;
    font-size: 50px;
    text-align: center;
    padding-top: 14px;

    box-shadow: 0 0 1px rgba(255, 0, 0, 0.4);
    background-color: #fff2f2;
  }
}
