// Bootstrap override
.pager {
  padding: 14px 0;
  margin: 0;

  li {
    > a,
    > a:hover,
    > a:focus {
      background: none;
      border: 0;
      padding: 0;
    }

    > a:hover {
      text-decoration: underline;
    }
  }
}
