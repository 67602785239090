// "More" menu: trigger

.more-menu-trigger {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  outline: none !important;

  .icon-more {
    color: #999;
    fill: #eee;
  }

  &:hover {
    .icon-more {
      color: #777;
      fill: #ccc;
    }
  }

  .icon-more-small {
    color: #ccc;
    fill: #f2f2f2;
  }

  &:hover {
    .icon-more-small {
      color: #aaa;
      fill: #ddd;
    }
  }
}


// "More" menu: tooltip content

ul.more-menu-items {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: block;
    padding: 0;

    &:hover {
      background-color: #eee;
    }
    &.danger:hover {
      color: #300 !important;
      background-color: #fcc !important;
    }

    a {
      display: block;
      padding: 6px 12px;

      color: #000;
      text-decoration: none;
      white-space: nowrap;
    }
    &:first-child a {
      padding-top: 8px;

      &:not(:last-child) a {
        padding-bottom: 5px;
      }
    }
    &:last-child a {
      padding-bottom: 8px;

      &:not(:first-child) a {
        padding-top: 5px;
      }
    }
  }
}
