body {
  background: #fff;
  position: relative;
  min-height: 100%;
  margin: 0 !important;

  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  overflow-anchor: none;

  &.mobile-sidebar-open {
    overflow: hidden;
  }
}

div.jsonly>p {
  padding-left: 2px;
}

@media (min-width: 1200px) {
  .container {
    width: 970px !important;
  }
}

@media (min-width: 992px) {
  .container.unauthenticated {
    width: 750px !important;
  }
}

a, a:hover, a:focus {
  cursor: pointer;
  color: #000088;
}

.settings-error {
  margin-left: 180px;
}

.settings-error {
  max-width: 174px;
}


.header-row{
  margin-bottom: 10px;
}

footer {
  color: #444;
  font-size: 12px;
  text-align: center;

  border-top: 1px solid #eee;
  padding-top: 14px;
  padding-bottom: 14px;

  a {
    color: #339;
  }
}

.error-string {
  color: #FF5A5F;
}


// Bootstrap override

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  margin-top: 3px; // instead of 4px
}


// PhotoSwipe override

.pswp__top-bar {
  background-color: transparent !important;
}
.pswp__counter,
.pswp__button--close {
  background-color: rgba(0, 0, 0, 0.3);
}
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  top: 28px;
  height: 44px;
  width: 44px;
}
.pswp__button--arrow--left:before {
  left: 0;
  background-position: -132px -37px;
}
.pswp__button--arrow--right:before {
  right: 0;
  background-position: -88px -37px;
}
