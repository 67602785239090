[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-bottom: -0.14em; // vertical-align an inline icon of 1em height in a text
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-ban {
  width: calc(12 / 14 * 1.0001em);
}
.icon-bars {
  width: calc(12 / 14 * 1.0001em);
}
.icon-bolt {
  width: calc(7 / 14 * 1.0001em);
}
.icon-bookmark {
  width: calc(21 / 28 * 1.0001em);
}
.icon-check-circle,
.icon-check-circle-o,
.icon-check-square {
  width: calc(12 / 14 * 1.0001em);
}
.icon-chevron-circle-right {
  width: calc(12 / 14 * 1.0001em);
}
.icon-clock {
  width: calc(12 / 14 * 1.0001em);
}
.icon-cloud-upload {
  width: calc(15 / 14 * 1.0001em);
}
.icon-envelope {
  width: calc(26 / 28 * 1.0001em);
}
.icon-exclamation {
  width: calc(10 / 28 * 1.0001em);
}
.icon-file,
.icon-file-audio {
  width: calc(12 / 14 * 1.0001em);
}
.icon-globe {
  width: calc(24 / 28 * 1.0001em);
}
.icon-home {
  width: calc(26 / 28 * 1.0001em);
}
.icon-level-down,
.icon-level-up {
  width: calc(16 / 28 * 1.0001em);
}
.icon-lock {
  width: calc(18 / 28 * 1.0001em);
}
.icon-more {
  width: calc(56 / 28 * 1.0001em);
}
.icon-more-small {
  width: calc(47 / 28 * 1.0001em);
}
.icon-plus {
  width: calc(10 / 14 * 1.0001em);
}
.icon-question-circle {
  width: calc(12 / 14 * 1.0001em);
}
.icon-search {
  width: calc(13 / 14 * 1.0001em);
}
.icon-times {
  width: calc(10 / 14 * 1.0001em);
}
.icon-thumbs-down,
.icon-thumbs-up {
  width: calc(25 / 28 * 1.0001em);
}
