.piece-of-text * {
  // In order to avoid flickering on hover when cursor moves between lines
  padding-top: 0.15em;
  padding-bottom: 0.15em;
}

.shortened-link {

  &:after {
    content: '\02026';
  }

  del {
    display: inline-block;
    width: 1px;
    height: 1px;
    margin-left: -1px;
    overflow: hidden;
  }
}
