.user-card {
  width: 263px;

  .user-card-info {
    min-height: 97px; // 75px + 2px + 20px
    padding: 10px 8px 0 10px;
    background-color: #fff;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;

    font-size: 12px !important;
    line-height: 15px;
    text-align: left;

    .userpic {
      float: left;
    }

    .display-name {
      font-weight: bold;
      margin-left: 87px;

      overflow-wrap: break-word;
      word-wrap: break-word;

      a {
        color: #000088 !important;
      }
    }

    .username {
      color: #999;
      margin: 3px 0 0 87px;

      .description-trigger {
        color: #ccc;
        padding: 1px 3px;
        margin-left: 2px;

        &:hover {
          cursor: pointer;
          background-color: #eee;
        }

        [class^="icon-"], [class*=" icon-"] {
          margin-bottom: -0.1em;
        }
      }
    }

    .description {
      color: #666;
      margin: 7px 0 0 87px;
      overflow: hidden;
    }

    .feed-status {
      color: #666;
      margin: 7px 0 0 87px;
    }

    .relationship-status {
      color: #666;
      margin: 7px 0 6px 87px;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .user-card-actions {
    border-top: 1px #e4e4e4 solid;
    padding: 5px 10px 6px 10px;
    background-color: #f4f4f4;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;

    color: #666;
    font-size: 13px !important;
    line-height: 17px;
    text-align: left;

    a {
      font-size: 13px !important;
      color: #555599 !important;
      font-weight: normal !important;
    }
  }
}

.dummy-user {
  .user-tile-name {
    color: #b8c0e6;
  }
}
