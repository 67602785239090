.search-form {
  input, button {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 34px;
  }

  button {
    margin-left: 5px;
  }

  &.in-header {
    display: none;
    @media (min-width: 768px) { display: block; }

    float: left;
    margin-top: 29px;

    opacity: 0.5;
    &.focused { opacity: 1; }
  }

  &.in-sidebar {
    margin: 0 0 15px 0;

    input { width: 78%; }
  }

  &.in-results {
    padding: 14px 0;
    border-bottom: 1px solid #eee;

    input { width: 72%; }
    button { margin-left: 10px; }
  }
}

.search-operators li {
  margin-bottom: 5px;
}
