// Comment Likes trigger + Comment More Menu trigger

.comment-likes-and-more {
  display: inline-block;
  width: 4.3em;
  height: 1.18em;
  vertical-align: text-bottom; // vertically align the outer container (with '-')
  white-space: nowrap;
  color: #ccc;

  // The "overflow-x: hidden, overflow-y: visible" problem is only solved with this additional container
  > span {
    display: inline-block;
    margin-top: -0.09em; // vertically align the inner container (with clikes and more-menu)
    max-width: 100%;
    overflow: hidden;
  }

  .clikes-trigger {
    position: relative;
    z-index: 2; // must be higher than in .more-menu-trigger

    min-width: 19px;
  }

  .more-menu-trigger {
    position: relative;
    z-index: 1; // must be lower than in .clikes-trigger, because its left padding is under clikes (see below)

    // To show tooltip, we need to properly catch both mousedown and mouseup events on the trigger.
    // 1. Since the trigger might move left after mousedown, we add RIGHT PADDING for catching mouseup.
    // 2. Then, for proper tooltip positioning in the center of the trigger, we also add equal LEFT PADDING.
    // 3. Then, to make left padding invisible to the user, we compensate it with MARGIN-LEFT.
    padding: 0 0.8em;
    margin-left: -0.8em;
  }
}

// Clike trigger

.comment-likes {
  white-space: nowrap;

  $clike-icon-color: #ffb914;

  .clikes-trigger {
    display: inline-block;
    height: 18px;
    padding: 0 4px;
    margin-left: 0;
    vertical-align: bottom;

    cursor: pointer;
    border-radius: 2px;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    outline: none;
  }

  .icon-heart {
    display: inline-block;
    margin-bottom: -0.5px;

    opacity: 0.8;
    font-size: 12px;

    color: $clike-icon-color;
    fill: lighten($clike-icon-color, 40%);
  }

  .clikes-number {
    display: inline-block;
    height: 18px;
    line-height: 18px;
    vertical-align: top;

    opacity: 0.7;
    color: #9d9d9d;
    font-size: 10px;
    margin-left: 2px;
  }

  .icon-plus,
  .icon-times {
    display: inline-block;
    line-height: 17px;
    vertical-align: baseline;
    margin-bottom: 0;

    color: $clike-icon-color;
    font-size: 11px;
    margin-left: 3px;
  }

  .icon-times {
    color: #ccc;
  }

  &.clikes-zero {
    color: #ddd;

    .icon-heart {
      color: #dcdcdc;
      fill: #fff;
    }
  }

  &.clikes-open {
    .clikes-trigger {
      background-color: #f2f2f2;
    }

    .icon-heart,
    .clikes-number {
      opacity: 1;
    }
  }

  &.clikes-liked {
    .icon-heart {
      fill: $clike-icon-color;
    }
  }

  .comment-from-archive & {
    color: #e0d4bf !important;

    .icon-heart {
      color: #f6c361 !important;
      fill: #fffdd6 !important;
    }

    &.clikes-zero {
      color: #f1e5ce !important;

      .icon-heart {
        color: #f2e4ca !important;
        fill: #ffffea !important;
      }
    }

    &.clikes-liked .icon-heart {
      fill: #f6c361 !important;
    }

    .clikes-number {
      color: #b0a796 !important;
    }
  }
}


// Tooltip content

.clikes-loading {
  color: #666;
  font-style: italic;
}

.clikes-error {
  color: #b22;
}

.clikes-empty-list {
  color: #666;
}

.clikes-list {
  list-style: none;
  padding: 0;
  margin: 0;

  color: #666;

  li {
    display: inline;
  }
}
