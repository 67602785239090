// Post: header (author and recipients)
$font-size-post-header: 15px;
$line-height-post-header: 21px;

// Post: body
$font-size-post-body: 16px;
$line-height-post-body: 22px;

// Post: footer (timestamp and actions)
$font-size-post-footer: 14px;
$line-height-post-footer: 19px;

// Single post: header (author and recipients)
$font-size-post-header-single: 19px;
$line-height-post-header-single: 26px;

// Single post: body
$font-size-post-body-single: 21px;
$line-height-post-body-single: 28px;


.post {
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #eee;

  // Clearfix (http://www.cssmojo.com/latest_new_clearfix_so_far/)
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  a {
    color: #555599;
  }

  .read-more {
    color: #555599 !important;
    font-style: italic;
    white-space: nowrap;
  }

  .p-break {
    display: block;
    height: 0.35em;
  }

  .pilcrow {
    cursor: pointer;

    &:hover {
      padding: 1px;
      margin: -1px;
      border-radius: 2px;
      background-color: #d9ebff;
    }
  }

  // Left part of post.
  // Includes userpic(s).
  .post-userpic {
    float: left;
    margin-right: 10px;

    &.has-secondary {
      position: relative;
      margin-bottom: 4px;

      .userpic-secondary {
        position: absolute;
        // `top` set in JS
        left: 17%;

        width: 33px;
        height: 33px;

        &.userpic-large {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  // Top part of post, it wraps around userpic on mobile.
  // Includes post-header and post-text/post-editor.
  .post-top {
    color: #666;

    margin-left: 52px + 10px;
    @media (max-width: 767px) { margin-left: 0; min-height: 52px; }

    margin-bottom: 8px;

    .post-header {
      font-size: $font-size-post-header;
      line-height: $line-height-post-header;
      margin-bottom: 2px;

      a {
        font-weight: bold;
        color: #000088;
      }

      .post-recipient {
        font-weight: 500;
      }
    }

    .post-text {
      color: #000;
      font-size: $font-size-post-body;
      line-height: $line-height-post-body;

      overflow-wrap: break-word;
      word-wrap: break-word;

      a {
        color: #000088;
      }
    }
  }

  // Bottom part of post, it goes below userpic on mobile.
  // Includes attachments, post-footer, likes and comments.
  .post-bottom {
    color: #666;
    font-size: $font-size-post-footer;
    line-height: $line-height-post-footer;

    margin-left: 52px + 10px;
    @media (max-width: 767px) { clear: both; margin-left: 0; }

    .post-footer {
      [class^="icon-"], [class*=" icon-"] {
        margin-right: 6px;
        margin-left: 1px;
      }

      .post-timestamp {
        color: #666;
      }

      .icon-more {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

.spoiler {
  > span {
    cursor: pointer;

    .blur-0, .blur-1, .blur-2, .blur-3, .blur-4,
    .blur-5, .blur-6, .blur-7, .blur-8, .blur-9 {
      filter: blur(0.060em);
      transition: filter 0.5s ease;
    }
  }

  > u {
    text-decoration: none;
    transition: filter 0.5s ease;
  }

  &-hidden > span {
    .blur-0 { filter: blur(0); }
    .blur-1 { filter: blur(0.015em); }
    .blur-2 { filter: blur(0.030em); }
    .blur-3 { filter: blur(0.045em); }
    .blur-4 { filter: blur(0.060em); }
    .blur-5 { filter: blur(0.075em); }
    .blur-6 { filter: blur(0.090em); }
    .blur-7 { filter: blur(0.105em); }
    .blur-8 { filter: blur(0.120em); }
    .blur-9 { filter: blur(0.135em); }
  }

  &-hidden > u {
    filter: blur(0.25em);
    cursor: pointer;

    * {
      pointer-events: none;
    }
  }
}

// Used in both create-post and edit-post
.post-editor {
  position: relative; // necessary for proper width of dropzone inside this container

  // Clearfix (http://www.cssmojo.com/latest_new_clearfix_so_far/)
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .post-textarea {
    @include editarea;

    color: #000;
    font-size: $font-size-post-body;
    line-height: $line-height-post-body;
  }

  .post-edit-options {
    float: left;
    line-height: 22px;
    margin-bottom: 8px;

    .post-edit-attachments {
      cursor: pointer;

      &:hover span:last-child {
        text-decoration: underline;
      }
    }

    .post-edit-more-trigger {
      color: #333;
      margin-left: 15px;
    }

    .post-edit-more {
      margin-top: 6px;

      .post-edit-more-checkbox {
        margin: 0 5px 0 1px;
        vertical-align: middle;
      }
      .post-edit-more-labeltext {
        font-weight: normal;
        vertical-align: middle;
      }
    }
  }

  .post-edit-actions {
    text-align: right;
    margin-bottom: 8px;

    [class^="icon-"], [class*=" icon-"] {
      position: absolute;
      margin: -30px 0 0 -22.1px; // ".1" is needed to fix a weird issue in Chrome causing wrongly rounded width on this element
      color: #666;
    }
  }

  .post-cancel {
    color: #555599;
    font-size: 12px;
    margin-right: 10px;
  }

  .post-error {
    margin: 10px 0 !important;
  }

  // This only affects attachments in create-post, not in edit-post (and this is fine)
  .attachments {
    clear: both;
  }
}

.recently-hidden-post {
  padding-left: 63px;
}

.single-post {
  border-top: 1px solid #eee;
  padding-top: 14px;
  padding-bottom: 0;
  border-bottom: 0 none;

  .post-top {
    margin-left: 77px + 10px;
    @media (max-width: 767px) { margin-left: 0; min-height: 77px; }

    .post-header {
      font-size: $font-size-post-header-single;
      line-height: $line-height-post-header-single;
    }

    .post-text {
      font-size: $font-size-post-body-single;
      line-height: $line-height-post-body-single;
    }
  }

  .post-bottom {
    margin-left: 77px + 10px;
    @media (max-width: 767px) { margin-left: 0; }
  }

  .post-editor {
    .post-textarea {
      font-size: $font-size-post-body-single;
      line-height: $line-height-post-body-single;
    }
  }
}

.direct-post {
  position: relative;
}

.post-direct-marker {
  position: absolute;

  color: desaturate(lighten(#1d66bf, 17%), 20%);
  opacity: 0.17; // To make it eventually the same color as the inside of a friend cloud icon
  font-size: 90px;
  top: 2px;
  right: 0;

  // Make it bigger on single post page
  .single-post & {
    font-size: 135px;
    top: 12px;
  }
}

.post-from-archive {
  // For post-archive-marker positioning
  position: relative;
  overflow: hidden;

  .post-header a {
    color: #226 !important;
  }

  .post-text {
    color: #432910 !important;

    a {
      color: #226 !important;
    }
  }

  .post-footer {
    color: #777166 !important;

    .post-timestamp {
      color: #777166 !important;
    }

    a {
      color: #6a6588 !important;
    }
  }

  .likes a {
    color: #6a6588 !important;
  }
}

.post-archive-marker {
  position: absolute;

  color: #704214;
  opacity: 0.2;
  font-size: 37px;

  // For big screens, put it below userpic
  top: 73px;
  left: 8px;

  @media (max-width: 767px) {
    // For small screens, put in the top right corner
    opacity: 0.1;
    left: inherit;
    top: 12px;
    right: 0;
  }

  // Make it bigger on single post page
  .single-post & {
    font-size: 56px;
    top: 102px;
    left: 11px;

    @media (max-width: 767px) {
      left: inherit;
      top: 11px;
      right: -1px;
    }
  }
}

.dummy-post {
  .post-top {
    color: #eee;

    .post-header a {
      color: #b8c0e6;
    }

    .post-text {
      color: #ddd;
    }
  }

  .post-bottom {
    color: #eee;

    .attachments .attachment {
      border-color: #e4e4e4;
      background-color: #f2f2f2;

      height: 100px;

      &.dummy-attachment-0 { width: 75px; }
      &.dummy-attachment-1 { width: 100px; }
      &.dummy-attachment-2 { width: 150px; }
    }

    .post-footer a {
      color: #ced5f2;
    }

    .likes {
      .likes-icon {
        float: none;
        margin: 0;
        color: #ffe099;
      }

      a {
        color: #ced5f2;
      }
    }
  }
}
