.comments {
  font-size: 14px;
  line-height: 19px;
}

.comment {
  color: #444;
  margin-top: 8px;

  .comment-icon {
    float: left;
    height: 16px;
    width: 16px;
    margin: 3px 2px 0 1px;

    text-decoration: none;
    line-height: 1em;

    .icon-comment {
      color: #999;
      fill: #eee;
    }

    &.comment-icon-important .icon-comment {
      color: desaturate(lighten(#1d66bf, 17%), 20%);
      fill: desaturate(lighten(#1d66bf, 50%), 20%);
    }

    &.comment-icon-mine .icon-comment {
      color: desaturate(lighten(#1d66bf, 17%), 20%);
      fill: desaturate(lighten(#1d66bf, 27%), 20%);
    }

    .icon-comment-plus {
      color: #999;
      fill: #fff;
    }

    &.comment-icon-add {
      margin-top: 2px;
    }
  }

  .comment-body {
    margin-left: 19px;

    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .comment-timestamp {
    color: #ccc;
    white-space: nowrap;

    a {
      color: #bbb;
    }
  }

  .comment-textarea {
    @include editarea;
  }

  .comment-edit-actions {
    font-size: 12px;

    display: flex;
    justify-content: space-between;

    .comment-attachments {
      cursor: pointer;
      color: #777;

      &:hover span {
        text-decoration: underline;
      }
    }

    .comment-attachments-uploading {
      color: #999;
      font-size: 10px;
    }

    .comment-cancel {
      color: #666;
      margin-right: 10px;
    }

    .comment-post {
      margin-top: -2px;
    }
  }

  .comment-error {
    margin: 10px 0 0 0 !important;
  }

  .more-comments-link {
    font-style: italic;
    text-decoration: none;
    margin-left: 19px;

    &:hover .more-comments-core {
      text-decoration: underline;
    }
  }

  .more-comments-clikes {
    font-style: italic;
    color: #bbb;
  }

  .add-comment-link {
    font-style: italic;
    margin-left: 1px;
  }

  &:hover {
    .reference-arrow {
      cursor: pointer;

      color: #333;
      background-color: #eee;
      border-radius: 2px;

      padding: 1px 3px;
      margin: -1px -3px;

      &:hover {
        background-color: #d9ebff;
      }
    }

    .icon-heart,
    .clikes-number {
      opacity: 1;
    }
  }

  &.hidden-comment {
    .comment-body,
    .comment-timestamp a,
    .comment-icon .icon-comment {
      color: #ccc;
      fill: #f4f4f4;
      font-style: italic;
    }
  }

  &.highlighted {
    background-color: #deedff !important; // Should override color in .targeted-comment
    border-radius: 3px;

    padding: 4px 7px 5px 7px;
    margin: 4px -7px -5px -7px;
  }

  &.targeted-comment {
    background-color: #ffb;
    border-radius: 3px;

    padding: 4px 7px 5px 7px;
    margin: 4px -7px -5px -7px;
  }

  &.comment-from-archive {
    .comment-icon {
      .icon-comment {
        color: #aca393 !important;
        fill: #fbf3df !important;
      }
      &.comment-icon-important .icon-comment {
        color: #8e9cab !important;
        fill: #fbf3ef !important;
      }
      &.comment-icon-mine .icon-comment {
        color: #8e9cab !important;
        fill: lighten(#8e9cab, 10%) !important;
      }
    }

    .comment-body {
      color: #777166 !important;

      a {
        color: #6a6588 !important;
      }

      .comment-timestamp {
        color: #e0d4bf !important;

        a {
          color: #cec4b0 !important;
        }
      }
    }

    &.hidden-comment {
      .comment-icon .icon-comment {
        color: #e0d4bf !important;
        fill: #fffbe2 !important;
      }

      .comment-body {
        color: #e0d4bf !important;

        .comment-timestamp a {
          color: #e0d4bf !important;
        }
      }
    }
  }
}

.comments-archive-revival {
  margin: 8px 0 -12px 0;

  .icon-bolt {
    margin-left: 5px;
  }
}
