.likes {
  margin-top: 8px;

  .likes-icon {
    float: left;
    height: 16px;
    width: 16px;
    margin: 2px 2px 0 1px;

    line-height: 1em;

    .icon-heart {
      color: #ffb914;
      fill: lighten(#ffb914, 40%);
    }

    &.likes-icon-liked .icon-heart {
      fill: #ffb914;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 4px 0 19px;

    li {
      display: inline;
    }
  }
}
